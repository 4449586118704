<template>
    <div class="objects position-relative">
        <div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative"
			 style="z-index:1000;">
			<div class="page-header">
				<h1 class="page-title" v-text="this.title" />
				<div class="d-flex position-relative">
					<div class="d-flex w-100 text-nowrap-mask scroller scroller-x position-sticky">
						<NavTabs :navItems="this.navTabs"
								 ref="navTabs"
								 @onClick="(target_id) => (this.resetFilter && this.loadObjects(1, null, target_id))" />
					</div>
				</div>
				<div class="d-flex align-items-center py-10">
					<div class="col d-flex">
						<router-link to="/add-object" class="btn d-flex align-items-center p-0 rounded-3">
							<span class="d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
								  style="width: 40px; height: 40px;">
								<img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">
							</span>
							<span class="text-font-dark fw-semi font-semi fs-4 ps-2">Новый объект</span>
						</router-link>
					</div>
					<div class="col d-flex">
						<div class="d-inline-flex ms-auto">
							<a href="javascript:{}"
							   class="d-flex align-items-center"
							   :class="(this.section == 'favor') ? 'text-black' : ''"
							   @click="this.loadObjects(1, null, 'favor')">
								<span class="btn btn-outline-secondary bg-light-gray me-10 border border-brd-primary rounded-1">
									<img :src="require('@/assets/icons/heart.svg')" alt="Избранное" width="18" height="18">
								</span>
								<span class="text-font-light fw-semi font-semi"
									  :class="(this.section == 'favor') ? 'text-black' : ''">Избранное</span>
								<span v-if="this.favouredCount > 0"
									  class="badge bg-danger ms-2 p-2 rounded-1"
									  v-text="this.favouredCount" />
							</a>
						</div>
					</div>
				</div>
				<div class="d-flex border border-brd-primary border-start-0 border-end-0 border-bottom-0 pt-3 pb-1">
					<div class="input-group">
						<button id="button-addon1"
								type="button"
								class="btn btn-outline-primary me-2 rounded-3 fw-semi font-semi"
								@click="this.isFilterOpened = !this.isFilterOpened">
							{{ this.filtersCountLabel }}
							<img :src="require('@/assets/icons/arr-sm-down-icon.svg')" class="ms-1 icon-color-primary" alt="Развернуть" width="12" height="12">
						</button>
						<input type="text" class="form-control border-0 px-1 me-2"
							   placeholder="ID или адрес объекта"
							   aria-label="Поиск по ID или адрес объекта"
							   ref="searchInput"
							   @change="(event) => this.search_query = event.target.value.toString()" />
						<button v-if="this.show_reset_filter" id="button-addon3" type="button" class="crm-btn btn btn-outline-secondary bg-light-gray me-2 me-0 border border-brd-primary rounded-1"
								aria-label="Очистить"
								@click="this.resetFilter()">
							<img :src="require('@/assets/icons/close-icon.svg')" alt="Очистить" width="14" height="14">
						</button>
						<button id="button-addon2" type="button" class="crm-btn btn btn-outline-secondary bg-light-gray me-0 border border-brd-primary rounded-1"
								aria-label="Осуществить поиск"
								@click="this.doSearch()">
							<img :src="require('@/assets/icons/search-icon.svg')" alt="Поиск" width="18" height="18">
						</button>
					</div>
				</div>
			</div>

			<Filters :isOpen="this.isFilterOpened"
					 section="objects"
					 :filters="this.filters"
					 :options="this.filterOptions"
					 @closeFilter="data => this.closeFilter(data)"
					 @setupFilter="this.filterChange"
					 @applyFilter="this.applyFilter" />
        </div>

		<ObjectsToolbar v-if="this.pagination.total > 0"
						ref="objectsToolbar1"
						:currentSection="this.section"
						:selectedCount="this.selected_count"
						@checkObjects='this.onClickSelectAll(true)'
						@uncheckObjects='this.onClickSelectAll(false)'
						@archiveObjects='(state) => this.addRemoveObjectsToArchive(state)'
						@transferObjects='this.setObjectsToTransfer'
						@sendObjectsToPDF='this.addObjectsToPDF'
						@clearAllSelected='this.clearAllSelected' />

		<ObjectsList :isAllChecked="this.isAllChecked"
					 ref="objectsList"
					 layout="default"
					 :tagsList="this.tagsList"
					 @selectedItems="(selected) => this.selected = selected"
					 @updateObjectsList="this.loadObjects"
					 :inputFilters="this.filters"
					 @objectsLoaded="this.objectsLoaded" />

		<ObjectsToolbar v-if="this.pagination.total > 0"
						:currentSection="this.section"
						:selectedCount="this.selected_count"
						@checkObjects='this.onClickSelectAll(true)'
						@uncheckObjects='this.onClickSelectAll(false)'
						@archiveObjects='(state) => this.addRemoveObjectsToArchive(state)'
						@transferObjects='this.setObjectsToTransfer'
						@sendObjectsToPDF='this.addObjectsToPDF'
						@clearAllSelected='this.clearAllSelected' />

		<Pagination v-if="this.pagination.pages > 1"
					:initialPage="this.pagination.current"
					:itemsList="this.pagination.items"
					:labels="{
						first: false,
						last: false,
						previous: false,
						next: false
					}"
					:pageSize="this.pagination.per_page"
					:disableDefaultStyles="true"
					@changePage="(page) => this.loadObjects(page)"
					:maxPages="3" />

	</div>

	<DelegateObjectModal
		:objectsIds="this.selected"
		@objectsTransfered="this.loadObjects()" />

	<CancelDelegateObjectModal
		@objectsTransferCanceled="this.loadObjects()" />

	<ResultsModal
		id="objects"
		:state="this.resultsModalState.state"
		:title="this.resultsModalState.title"
		:message="this.resultsModalState.message"
	/>

	<RequisitionsModal />

	<SubmitComplaintModal />
	<SubmitToVKModal />
	<CustomFieldsFilterModal @setupFields="this.setCustomFields" />
	<PresentationNav />

</template>

<script>
    import CommonService from "../services/CommonService";
    import ObjectsToolbar from '../components/objects/ObjectsToolbar';
    /*import ObjectCardList from '@/components/common/ObjectCardList';*/
	import ObjectsList from '@/components/objects/ObjectsList';
    import NavTabs from '../components/common/NavTabs';
    import Pagination from '../components/common/Pagination';
    import Filters from '../components/common/Filters.vue';
	import api from "@/api";
	import ResultsModal from "@/components/modals/ResultsModal";
	import DelegateObjectModal from "@/components/modals/DelegateObjectModal";
	import CancelDelegateObjectModal from "@/components/modals/CancelDelegateObjectModal";
	import SubmitComplaintModal from "@/components/modals/SubmitComplaintModal";
	import SubmitToVKModal from "@/components/modals/SubmitToVKModal";
	import CustomFieldsFilterModal from "@/components/modals/CustomFieldsFilterModal";
	import PresentationNav from "@/components/common/PresentationNav";
	import RequisitionsModal from "@/components/modals/RequisitionsModal";

    export default {
        name: "objects",
		props: {
			funnelId: {type: Number, default: 0 }
		},
        data() {
            return {
                title: "Мои объекты",
				section: 'active',
				selected: [],
                navTabs: {
					'active': {id: 'active', label: "В работе", title: "В работе", count: 0, active: false, href: '#', action: () => console.log('NavTab click!')},
					'potential': {id: 'potential', label: "Потенциальные", title: "Потенциальные", count: 0, active: false, href: '#', action: null},
					'archive': {id: 'archive', label: "Архив", title: "Архив", count: 0, active: false, href: '#', action: null},
				},
				favouredCount: 0,
				filters: {
					operation_type: null,
				},
				filtersCount: 0,
				filtersCountLabel: 'Фильтр',
				isFilterOpened: false,
				search_query: '',
				filterOptions: {
					types: {
						'sale': {
							label: 'Продажа',
							count: 0,
							title: 'Объекты в продаже',
						},
						'rent': {
							label: 'Аренда',
							count: 0,
							title: 'Объекты в аренде',
						},
					}
				},
				pagination: {
					page: 0,
					pages: 0,
					count: 0,
					total: 0,
					per_page: 10,
				},
				isAllChecked: false,
				resultsModalState: {
					state: '',
					title: '',
					message: ''
				},
				tagsList: [],
            };
        },
        methods: {
			onClickSelectAll(state) {
				if (typeof state != "undefined") {
					if (state === true) {
						for (const [key, value] of Object.entries(this.$refs.objectsList.objectsItems)) {
							if (!this.selected.includes(value.id)) {
								this.selected.push(value.id);
							}
						}
						this.isAllChecked = true;

						CommonService.log('log', 'onClickSelectAll()::true', this.selected);

					} else {
						for (const [key, value] of Object.entries(this.$refs.objectsList.objectsItems)) {
							if (this.selected.includes(value.id)) {
								let index = this.selected.indexOf(value.id);
								this.selected.splice(index, 1);
							}
						}
						this.isAllChecked = false;

						CommonService.log('log', 'onClickSelectAll()::false', this.selected);
					}
				} else {
					this.isAllChecked = !this.isAllChecked;

					CommonService.log('log', 'onClickSelectAll()', this.selected);
				}

				this.$refs.objectsList.selectedItems = this.selected;

			},
			onClickDeSelectAll() {
				//this.selected = [];
				//this.$refs.objectsList.selectedItems = this.selected;
				this.isAllChecked = false;

				CommonService.log('log', 'onClickDeSelectAll()', this.selected);
			},
			addRemoveObjectsToArchive(state) {

				this.selected = this.$refs.objectsList.selectedItems;
				CommonService.log('log', 'addRemoveObjectsToArchive()', this.selected);

				if (this.selected.length > 0) {
					api.put('/objects/archived', {
						objects_ids: this.selected,
						current: (state)
					}).then((response) => {
						CommonService.log('debug', 'addRemoveObjectsToArchive()::axios', { response });

						if (response.status == 200 && response.data.success) {

							state = response.data.state;
							if (!state) {
								this.resultsModalState.state = 'success';
								this.resultsModalState.title = 'Ok';
								this.resultsModalState.message = 'Объекты были успешно восстановлены из архива!';
							} else {
								this.resultsModalState.state = 'success';
								this.resultsModalState.title = 'Ok';
								this.resultsModalState.message = 'Объекты были успешно перенесены в архив!';
							}

							setTimeout(this.loadObjects(false, false, this.section, this.filters), 2000);
						}

					}).catch(function (error) {
						this.resultsModalState.state = 'error';

						CommonService.log('error', 'addRemoveObjectsToArchive()::axios', error);

					});
				} else {
					this.resultsModalState.state = 'error';
					this.resultsModalState.title = 'Ошибка';
					this.resultsModalState.message = 'Не выделены объекты для перемещения в архив!';
				}
			},
			setObjectsToTransfer() {

				this.selected = this.$refs.objectsList.selectedItems;
				CommonService.log('log', 'setObjectsToTransfer()', this.selected);

				let modal = CommonService.getModal('delegateObjectModal');
				if (modal)
					modal.show();

			},
			addObjectsToPDF() {

				this.selected = this.$refs.objectsList.selectedItems;
				CommonService.log('log', 'addObjectsToPDF()', this.selected);

				this.$store.commit('setObjectsToPDF', this.selected);
			},
			clearAllSelected() {

				this.selected = []
				this.$refs.objectsList.selectedItems = [];
				this.$store.commit('setObjectsToPDF', []);
				CommonService.log('log', 'clearAllSelected()', this.selected);

			},
			filterChange(filters) {

				filters = CommonService.removeEmpty(filters);
				this.filters = filters;

				CommonService.log('log', 'filterChange()', this.filters);

				this.filtersCount = Object.keys(this.filters).length;
			},
			applyFilter(filters) {

				this.isFilterOpened = false;

				filters = CommonService.removeEmpty(filters);

				CommonService.log('log', 'applyFilter()', this.filters);

				this.loadObjects(false, false, this.section, this.filters);
			},
			doSearch() {
				let query = this.search_query;
				if (!CommonService.isEmpty(query)) {
					CommonService.log('log', 'doSearch()', query);
					this.loadObjects(false, false, this.section, this.filters, this.search_query);
				}
			},
			loadObjects(page, per_page, section, filters, search) {

				this.pagination = {};
				this.isAllChecked = false;

				if (!page)
					page = 1;

				if (!per_page)
					per_page = 10;

				if (!filters)
					filters = this.filters;

				if (!search)
					search = null;

				if (section && typeof section !== "undefined")
					this.section = section;

				/*if (this.section == 'favor')
					this.active_tab = null*/

				CommonService.log('log', 'loadObjects()', [page, per_page, this.section]);

				this.isFilterOpened = false;
				this.$refs.objectsList.loadObjects(page, per_page, this.section, filters, search, true);
			},
			objectsLoaded(data) {

				if (data.counters) {

					if (typeof data.counters.active != "undefined")
						this.navTabs['active'].count = data.counters.active;

					if (typeof data.counters.potential != "undefined")
						this.navTabs['potential'].count = data.counters.potential;

					if (typeof data.counters.archive != "undefined")
						this.navTabs['archive'].count = data.counters.archive;

					if (typeof data.counters.favoured != "undefined")
						this.favouredCount = data.counters.favoured;

					if (typeof data.counters.sale != "undefined")
						this.filterOptions.types['sale'].count = data.counters.sale;

					if (typeof data.counters.rent != "undefined")
						this.filterOptions.types['rent'].count = data.counters.rent;

				}

				for (const [key, tab] of Object.entries(this.navTabs)) {
					tab['active'] = false;
				}

				if (this.navTabs[this.section])
					this.navTabs[this.section]['active'] = true;

				if (data.pagination) {

					this.pagination = data.pagination;

					CommonService.log('log', 'objectsLoaded()::pagination', this.pagination);

				}

			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			getTags() {
				return api.get('/common/tags').then((response) => {

					CommonService.log('debug', 'getTags()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								id: option.id,
								name: option.name,
								isChecked: false
							});
						});

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getTags()::axios', error);

				});
			},
			setCustomFields(fields) {

				CommonService.log('log', 'setCustomFields()', fields);

				this.filters['custom_fields'] = fields;
			},
			resetFilter() {

				this.$refs.searchInput.value = null;
				if (Object.keys(this.filters).length > 0) {

					this.filters = {
						operation_type: null,
					};
					this.filtersCount = 0;
					CommonService.log('log', 'resetFilter()', this.filters);
				}

				this.loadObjects(false, false, this.section, this.filters);
			},
			closeFilter(clear) {
				this.isFilterOpened = false;

				if (clear) {
					this.resetFilter();
				}
			},
        },
        components: {
			RequisitionsModal,
			PresentationNav,
			CustomFieldsFilterModal,
			SubmitToVKModal,
			SubmitComplaintModal,
			DelegateObjectModal,
			CancelDelegateObjectModal,
			ResultsModal,
            ObjectsToolbar,
			ObjectsList,
            /*ObjectCardList,*/
            NavTabs,
			Filters,
            Pagination,
        },
        mounted() {
            CommonService.setPageTitle(this.title);

			if (typeof this.$props.funnelId !== "undefined")
				this.filterOptions.funnel_id = Number(this.$props.funnelId);

			if (this.filtersCount > 0)
				this.filtersCountLabel = CommonService.declOfNum(this.filtersCount, ['опция', 'опции', 'опций'], true);

			this.selected = [];
			this.selected.length = 0;

			if (!this.isEmpty(this.$store.getters.tagsList)) {
				let list = [];
				Object.entries(this.$store.getters.tagsList).forEach(([key, option]) => {
					list.push({
						id: option.value,
						name: option.name,
						isChecked: false
					});
				});
				this.tagsList = list;
			} else {
				this.getTags().then(data => {
					this.tagsList = data;
				});
			}

			this.isAllChecked = false;
			//this.$refs.objectsList.loadObjects(1, 10, this.section, this.filters);

			this.$nextTick(() => {
				const item_el = document.getElementById(this.$route.hash);
				if (item_el) {
					item_el && item_el.scrollIntoView();
				}
			});

			this.emitter.on('closeFilter', () => this.resetFilter());
        },
		/*computed: {
			filtersCountLabel: function () {
				if (this.filtersCount > 0) {
					this.filtersCountLabel = CommonService.declOfNum(this.filtersCount, ['опция', 'опции', 'опций'], true);
					return this.filtersCountLabel;
				}
			}
		},*/
		watch: {
			'selected': function(val, oldVal) {
				CommonService.log('debug', 'watch:selected', {value: val, old_value: oldVal});
			},
			items: function (value) {
				if (!CommonService.isEmpty(this.$route.hash)) {
					this.$nextTick(() => {
						CommonService.debounce(async () => {
							const item_el = document.querySelector(this.$route.hash);
							if (item_el) {
								item_el && item_el.scrollIntoView();
							}
						}, 600);
					});
				}
			},
			filtersCount: function (value) {
				if (value > 0) {
					this.filtersCountLabel = CommonService.declOfNum(value, ['опция', 'опции', 'опций'], true);
				} else {
					this.filtersCountLabel = 'Фильтр';
				}
			}
		},
		computed: {
			selected_count() {
				let count = this.selected.length;
				console.log(count);
				return this.selected.length;
			},
			show_reset_filter() {
				return !this.isEmpty(this.search_query);
			}
		},
    };
</script>